import { render, staticRenderFns } from "./EditUser.vue?vue&type=template&id=67c55790&scoped=true"
import script from "./EditUser.vue?vue&type=script&lang=js"
export * from "./EditUser.vue?vue&type=script&lang=js"
import style0 from "./EditUser.vue?vue&type=style&index=0&id=67c55790&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67c55790",
  null
  
)

export default component.exports